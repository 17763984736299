<template>
  <div class="d-flex flex-column px-4 py-4">
    <h2 class="mb-4 cursive-font">Manage Client Subscription</h2>

    <v-data-table :headers="headers" :items="clients" item-key="id" class="elevation-1" :search="search"
      :custom-filter="filterOnlyCapsText" :page.sync="page" :loading="loading" :server-items-length="totalItems"
      :items-per-page.sync="itemsPerPage" sort-desc.sync="false" @update:sort-by="handleSortType"
      @update:sort-desc="handleSort" @update:page="handlePageChange" loading-text="Loading... Please wait">

      <template v-slot:top>
        <v-text-field v-model="search" label="Search (Lower Case Only)" class="mx-4"></v-text-field>
      </template>

      <template v-slot:item.is_subscribed="{ item }">
        <v-switch v-model="item.is_subscribed" :label="`${(item.is_subscribed === 1) ? 'Active' : 'Disable'}`"
          @change="onSubscriptionStatusUpdate($event, item.id)"></v-switch>
      </template>


    </v-data-table>

  </div>
</template>

<script>
import TableWithFilters from "@/components/common/TableWithFilters.vue";
import { computed, onMounted, ref } from "@vue/composition-api";
import { VTextField, VCheckbox } from "vuetify/lib";
import axios from "@axios";
export default {
  setup() {
    const search = ref('')
    const calories = ref('')
    const loading = ref(false)
    const switchloading = ref(false)
    const page = ref(1)
    const itemsPerPage = ref(0)
    const totalItems = ref(0)
    const sortdir = ref('')
    const sortby = ref('')
    const headers = computed(() => [
      {
        text: 'Customer ID',
        value: 'id',
        sortable: true
      },
      {
        text: 'Full Name',
        value: 'username',
        sortable: false
      },
      {
        text: 'Email',
        value: 'email',
        sortable: false
      },
      {
        text: 'Subscription',
        value: 'is_subscribed',
        sortable: true
      },
    ])

    const clients = ref([])

    async function fetchTableData() {
      try {
        loading.value = true;
        const { data } = await axios.get('admin/clients',
          { params: { page: page.value, sortby: sortby.value, sortdir: sortdir.value } }
        );
        clients.value = data.results
        page.value = parseInt(data.page);
        itemsPerPage.value = data.itemsPerPage;
        totalItems.value = data.total;
      } catch (err) {
        console.error(err);
        throw err
      } finally {
        loading.value = false;
      }
    }

    onMounted(() => {
      fetchTableData();
    });

    const filterOnlyCapsText = (value, search, item) => {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLowerCase().indexOf(search) !== -1
    }

    const onSubscriptionStatusUpdate = async (event, id) => {
      try {
        const { data } = await axios.patch(`/admin/user-subscription/`, {
          clientId: id,
          isSubscribed: event
        });
        if (data) {
          fetchTableData()
        }
      } catch (err) {
        console.error(err)
        throw err
      }
    }

    function handlePageChange(event) {
      page.value = parseInt(event);
      fetchTableData();
    }

    function handleSort(event) {
      console.log('handleSort : ', event)
      if (event == false) {
        sortdir.value = 'asc'
      }
      else {
        sortdir.value = 'desc'
      }
      fetchTableData();
    }

    function handleSortType(event) {
      console.log('handleSortType : ', event)
      sortby.value = event
    }

    return {
      itemsPerPage,
      totalItems,
      page,
      handlePageChange,
      switchloading,
      loading,
      headers,
      clients,
      handleSort,
      TableWithFilters,
      handleSortType,
      VTextField,
      VCheckbox,
      filterOnlyCapsText,
      search,
      calories,
      onSubscriptionStatusUpdate
    }
  }
}

</script>

<style scoped>
thead.v-data-table-header th[role=columnheader] {
  font-size: 18px !important;
}
</style>